import React from 'react';
import BillingRecurring from './BillingRecurring';
import ContractsRecurring from './ContractsRecurring';
import CobrancaFaturarContext from '../../../Context/CobrancaFaturarContext';
import StudentFormContext from '../../../Context/StudentFormContext';
import { useTranslation } from 'react-i18next';
import { getStandardizedContractPlan, getStandardizedInvoicePlan } from '../../../services/ContractStudentService';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

const List = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const alertRef = React.useRef();

  const [standContracts, setStandContracts] = React.useState([]);
  const [standInvoices, setStandInvoices] = React.useState([]);

  const [invoiceLoad, setInvoiceLoad] = React.useState(true);
  const [contractLoad, setContractLoad] = React.useState(true);

 

  //contructor ----------------------------------------------------------------------------
  React.useEffect(() => {
    const schoolSession = JSON.parse(localStorage.getItem('school'));

    (async () => {
      try {
        const data = await getStandardizedContractPlan(schoolSession.id);
        setStandContracts(data);

        const dataInvoice = await getStandardizedInvoicePlan(schoolSession.id);
        setStandInvoices(dataInvoice);
      } catch (error) {
        console.log(error);
      } finally {
        setInvoiceLoad(false);
        setContractLoad(false);
      }
    })();
  }, []);

  return (
    <CobrancaFaturarContext>
      <StudentFormContext>
        
        <div className='card p-0 flex-column'>
          <div className='w-100 p-3'>
            <div className='row'>
              <div className='col-6 text-left'>
                <div>Contrato/Recorrente: Planos criados</div>
              </div>
              <div className='col-6 text-right'>
                <button
                  type='button'
                  className='btn btn-light btn-sm'
                  style={{ backgroundColor: 'var(--main-color)', color: 'white', width: '100px' }}
                  onClick={() => {
                    history.push('/standardized-plans/create-contract');
                  }}
                >
                  <svg
                    focusable='false'
                    aria-hidden='true'
                    aria-label='fontSize small'
                    fill='none'
                    style={{ position: 'relative', width: 20, height: 20, top: -2, left: -8 }}
                  >
                    <path d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z' fill='#FFFF'></path>
                  </svg>
                  {t('Add')}
                </button>
              </div>
            </div>
          </div>
          <hr className='mt-0' />
          <div className='card_body w-100 flex-column align-items-center'>
            <ContractsRecurring data={standContracts} loading={contractLoad} />
          </div>
        </div>
       {/*  <div className='card p-0 flex-column'>
          <div className='w-100 p-3'>
            <div className='row'>
              <div className='col-6 text-left'>
                <div>Cobranças avulsas: Planos criados</div>
              </div>
              <div className='col-6 text-right'>
                <button
                  type='button'
                  className='btn btn-light btn-sm'
                  style={{ backgroundColor: 'var(--main-color)', color: 'white', width: '100px' }}
                  onClick={() => {
                    history.push('/standardized-plans/create-invoice-revenue');
                  }}
                >
                  <svg
                    focusable='false'
                    aria-hidden='true'
                    aria-label='fontSize small'
                    fill='none'
                    style={{ position: 'relative', width: 20, height: 20, top: -2, left: -8 }}
                  >
                    <path d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z' fill='#FFFF'></path>
                  </svg>
                  {t('Add')}
                </button>
              </div>
            </div>
          </div>
          <hr className='mt-0' />
          <div className='card_body w-100 flex-column align-items-center'>
            <BillingRecurring data={standInvoices} loading={invoiceLoad} />
          </div>
        </div> */}
      </StudentFormContext>
    </CobrancaFaturarContext>
  );
};

export default List;
